// import axios from "axios";
// import toast from "react-hot-toast";
// import getCookie from "../../../Component/getCookie";

// export const uploadImages = async (
//   browsedFiles,
//   setUploading,
//   setBrowsedFiles,
//   fileInputRef
// ) => {
//   setUploading(true);
//   const results = [];

//   if (browsedFiles?.length > 0) {
//     for (let i = 0; i < browsedFiles.length; i++) {
//       const image = new FormData();
//       image.append("image", browsedFiles[i]);

//       try {
//         const response = await axios.post(
//           `https://api.imgbb.com/1/upload?key=${process.env.REACT_APP_PHOTO_URL}`,
//           image
//         );

//         if (response.data.data.url) {
//           results.push({ img: response.data.data.url });
//         }
//       } catch (error) {
//         toast.error(error.message);
//       }
//     }
//   }

//   if (results.length > 0) {
//     axios
//       .post(`${process.env.REACT_APP_URL}/upload-images`, results, {
//         headers: {
//           Authorization: `Bearer ${getCookie("khoboraccess_token")}`,
//         },
//       })
//       .then((res) => {
//         if (res?.data?.acknowledged) {
//           toast.success("Image upload success");
//         }
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   }

//   setUploading(false);
//   setBrowsedFiles([]);
//   if (fileInputRef.current) {
//     fileInputRef.current.value = "";
//   }
// };

import axios from "axios";
import toast from "react-hot-toast";
import getCookie from "../../../Component/getCookie";

export const uploadImages = async (
  browsedFiles,
  setUploading,
  setBrowsedFiles,
  fileInputRef
) => {
  setUploading(true);
  const results = [];

  if (browsedFiles?.length > 0) {
    for (let i = 0; i < browsedFiles.length; i++) {
      const image = new FormData();
      image.append("file", browsedFiles[i]);
      image.append(
        "upload_preset",
        process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
      );

      try {
        const response = await axios.post(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
          image
        );

        if (response?.data?.secure_url) {
          results.push({ img: response?.data?.secure_url });
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  }

  if (results.length > 0) {
    axios
      .post(`${process.env.REACT_APP_URL}/upload-images`, results, {
        headers: {
          Authorization: `Bearer ${getCookie("khoboraccess_token")}`,
        },
      })
      .then((res) => {
        if (res?.data?.acknowledged) {
          toast.success("Image upload success");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  setUploading(false);
  setBrowsedFiles([]);
  if (fileInputRef.current) {
    fileInputRef.current.value = "";
  }
};
