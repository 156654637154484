import React, { useContext, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthProvider";
import { Helmet } from "react-helmet";

const SorboseshNews = () => {
  const { data, leadNews, setLeadNews } = useContext(AuthContext);
  const finalData = data?.filter((item) => item?._id !== leadNews?._id);
  let leftSiteData = finalData?.slice(0, 6);
  const rightSiteData = finalData?.slice(6, 12);

  useEffect(() => {
    const fetchLeadNews = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/leadNews`
        );
        const dt = response.data;
        setLeadNews(dt[0]);
      } catch (error) {
        console.error("Error fetching news titles:", error);
      }
    };

    fetchLeadNews();
  }, [setLeadNews]);

  return (
    <>
      <Helmet>
        <title>আজকের খবর – সত্য প্রকাশে বদ্ধ পরিকর</title>
        <meta
          property="og:title"
          content="আজকের খবর – সত্য প্রকাশে বদ্ধ পরিকর"
        />
        <meta
          name="description"
          content="আজকের খবর – সত্য প্রকাশে বদ্ধ পরিকর"
        />
        <meta
          property="og:description"
          content="আজকের খবর – সত্য প্রকাশে বদ্ধ পরিকর"
        />
        <meta
          property="og:image"
          content="https://i.ibb.co/jJn0SVM/ajkerkhobor2.png"
        />
        <meta property="og:url" content="https://ajkerkhobor.atnmt.com" />
      </Helmet>
      <div>
        <div className="flex flex-col-reverse md:flex-row gap-4">
          <div className="md:w-[50%]">
            {leftSiteData?.map((d, index) => {
              return (
                <div key={index} className="border-b my-5">
                  <Link
                    to={`news/${d?._id}`}
                    className="text-xl hover:text-blue-600 font-bold mb-1"
                    state={d}
                  >
                    {d?.title}
                  </Link>
                </div>
              );
            })}
          </div>
          <div className="w-full flex flex-col justify-center items-center lg:mt-5">
            <div className="w-full min-h-[160px] bg-gray-300 overflow-hidden rounded-md md:min-h-[300px] md:bg-white md:overflow-auto md:flex justify-center">
              <img
                className="md:max-h-[450px] rounded-md"
                src={leadNews?.img}
                alt=""
                loading="lazy"
              />
            </div>
            <h1 className="text-2xl md:text-3xl font-bold my-2 text-center text-blue-500 hover:text-red-600">
              <Link to={`/news/${leadNews?._id}`} state={leadNews}>
                {leadNews?.title}
              </Link>
            </h1>
          </div>
          <div className="md:w-[50%]  hidden md:block">
            {rightSiteData?.map((d, index) => {
              return (
                <div key={index} className="border-b border-gray-300 my-5">
                  <Link
                    to={`news/${d?._id}`}
                    className="text-xl hover:text-blue-600 font-bold mb-1"
                    state={d}
                  >
                    {d?.title}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SorboseshNews;
