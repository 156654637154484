import AntorjatikNews from "./AntorjatikNews/AntorjatikNews";
import JatioyoNews from "./BangladeshNews/JatiyoNews";
import BinodonNews from "./BinodonNews/BinodonNews";
import KhelaNews from "./KhelaNews/KhelaNews";
import Motamot from "./Motamot/Motamot";
import Projukti from "./Projukti/Projukti";
import Shahitto from "./Shahitto/Shahitto";
import Shikkha from "./Shikkha/Shikkha";
import BennerNews from "./BannerNews/BannerNews";
import Sylhet from "./Sylhet/Sylhet";
import Companiganj from "./Companiganj/Companiganj";
import Vromon from "./Vromon/Vromon";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../Context/AuthProvider";
import "../../Component/styles.css";

const Home = () => {
  const { data, loading, setLoading } = useContext(AuthContext);
  window.scrollTo(0, 0);
  useEffect(() => {
    if (data?.length) {
      setLoading(true);
    }
  }, [data, setLoading]);

  if (!data?.length || !loading) {
    return (
      <div className="w-full">
        <div className="w-full h-[32px] skeleton-placeholder"></div>
        <div className="w-[88px] h-[25px] skeleton-placeholder"></div>
        <div className="md:flex gap-5">
          <div className="w-[30%] h-[200px] md:h-[400px] mt-2 hidden md:block">
            <div className="h-[123px] skeleton-placeholder bg-red-500 mt-2"></div>
            <div className="h-[123px] skeleton-placeholder bg-red-500 mt-2"></div>
            <div className="h-[123px] skeleton-placeholder bg-red-500 mt-2"></div>
          </div>
          <div className="w-full h-[200px] md:h-[400px] mt-2 skeleton-placeholder"></div>
          <div className="w-[30%] h-[200px] md:h-[400px] mt-2 hidden md:block">
            <div className="h-[123px] skeleton-placeholder bg-red-500 mt-2"></div>
            <div className="h-[123px] skeleton-placeholder bg-red-500 mt-2"></div>
            <div className="h-[123px] skeleton-placeholder bg-red-500 mt-2"></div>
          </div>
        </div>
        <div className="w-[90%] md:w-[60%] mx-auto">
          <div className="w-full h-[40px] mt-2 skeleton-placeholder"></div>
        </div>
        <div className="my-4 md:w-[60%] mx-auto">
          <div className="w-full h-[8px] mt-2 skeleton-placeholder"></div>
          <div className="w-full h-[8px] mt-2 skeleton-placeholder"></div>
          <div className="w-[70%] h-[8px] mt-2 skeleton-placeholder"></div>
        </div>
        <div className="w-[90%] md:w-full h-[35px] md:h-5 mt-2 skeleton-placeholder"></div>
        <div className="w-[90%] md:hidden h-[35px] mt-2 skeleton-placeholder"></div>
        <div className="w-[90%] md:hidden h-[35px] mt-2 skeleton-placeholder"></div>
      </div>
    );
  }

  return (
    <div className="">
      <BennerNews />
      <Companiganj />
      <Sylhet />
      <JatioyoNews />
      <AntorjatikNews />
      <KhelaNews />
      <BinodonNews />
      <Motamot />
      <Shikkha />
      <Vromon />
      <Projukti />
      <Shahitto />
    </div>
  );
};

export default Home;
