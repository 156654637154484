import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthProvider";
import VideoPlayer from "../../../Component/VideoPlayer";

const Companiganj = () => {
  const { setScrollY, data } = useContext(AuthContext);
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchLeadNews = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/category`,
          { params: { number: 5, category: "কোম্পানীগঞ্জ" } }
        );
        const dt = response.data;
        setNewsData(dt);
      } catch (error) {
        console.error("Error fetching news titles:", error);
      }
    };

    fetchLeadNews();
  }, []);

  if (!data?.length) {
    return <></>;
  }

  return (
    <>
      {newsData?.length ? (
        <div className="my-10">
          <div className="flex items-center w-full my-8">
            <div className="w-full">
              <p className="w-full border-b border-gray-500"></p>
              <p className="w-full mt-1 border-b border-gray-500"></p>
            </div>
            <p className="px-3 text-3xl font-bold">কোম্পানীগঞ্জ </p>
            <div className="w-full">
              <p className="w-full border-b border-gray-500"></p>
              <p className="w-full mt-1 border-b border-gray-500"></p>
            </div>
          </div>
          <div className="md:w-[80%] mx-auto">
            <div className="flex flex-col md:flex-row gap-8">
              <div className="md:w-[50%]">
                <div className="w-full min-h-[160px] bg-gray-300 overflow-hidden rounded-md md:min-h-[250px] md:bg-gray-300">
                  {newsData[0]?.video ? (
                    VideoPlayer(newsData[0]?.video)
                  ) : (
                    <img
                      className="w-full rounded-md"
                      src={newsData[0]?.img}
                      alt="img"
                      loading="lazy"
                    />
                  )}
                </div>
                <div className="my-3">
                  <Link
                    to={`/news/${newsData[0]?._id}`}
                    state={newsData[0]}
                    className="text-2xl md:text-3xl font-bold text-blue-500 hover:text-red-500"
                  >
                    {newsData[0]?.title}
                  </Link>
                </div>
              </div>
              <div className="md:w-[50%]">
                {newsData?.slice(1, 6)?.map((d, index) => (
                  <Link
                    to={`/news/${d?._id}`}
                    state={d}
                    key={index}
                    className="flex w-full items-center gap-2 border p-1 rounded-md mb-2"
                  >
                    <img
                      className="w-[108px] h-20 rounded-md"
                      src={d?.img}
                      alt="img"
                      loading="lazy"
                    />
                    <p className="text-md md:text-lg font-bold">{d?.title}</p>
                  </Link>
                ))}
              </div>
            </div>
            <Link
              onClick={() => setScrollY(true)}
              to="/companiganj"
              className="bg-blue-500 text-white font-bold py-2 px-6 flex m-10 justify-center items-center w-[80px] mx-auto rounded-md"
            >
              আরও
            </Link>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Companiganj;
