// import axios from "axios";

// const SetDataInDB = async ({
//   newPost,
//   image,
//   formRef,
//   formData,
//   videoUrl,
//   times,
//   description,
//   checkLead,
//   setCheckLead,
// }) => {
//   try {
//     const { title, subTitle, category, postTime, postBy } = formData;

//     let url = image;

//     if (image?.name) {
//       const img1 = new FormData();
//       img1?.append("image", image);
//       const upload = await axios.post(
//         `https://api.imgbb.com/1/upload?key=${process.env.REACT_APP_PHOTO_URL}`,
//         img1
//       );
//       url = upload?.data?.data?.url;
//     }

//     const newsData = {
//       title,
//       subTitle,
//       img: url,
//       video: videoUrl,
//       category,
//       postTime,
//       times,
//       leadNews: checkLead,
//       postBy,
//       des: description,
//     };
//     newPost(newsData);

//     setCheckLead(formRef.current.elements.leadNews.checked);
//     formRef.current.reset();
//   } catch (err) {
//     console.log(err);
//   }
// };
// export default SetDataInDB;

import axios from "axios";

const SetDataInDB = async ({
  newPost,
  image,
  formRef,
  formData,
  videoUrl,
  times,
  description,
  checkLead,
  setCheckLead,
}) => {
  try {
    const { title, subTitle, category, postTime, postBy } = formData;

    let url = image;

    if (image?.name) {
      const img1 = new FormData();
      img1.append("file", image);
      img1.append(
        "upload_preset",
        process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
      );

      const upload = await axios.post(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
        img1
      );
      url = upload.data.secure_url;
    }

    const newsData = {
      title,
      subTitle,
      img: url,
      video: videoUrl,
      category,
      postTime,
      times,
      leadNews: checkLead,
      postBy,
      des: description,
    };
    newPost(newsData);

    setCheckLead(formRef.current.elements.leadNews.checked);
    formRef.current.reset();
  } catch (err) {
    console.log(err);
  }
};

export default SetDataInDB;
